import React, { useState } from 'react';
import './App.css';
import Footer from './Footer';

function Others() {
  const [filter, setFilter] = useState('');

  let other_gis_data_sources = [
    {
      title: 'SIE HAITI',
      subtitle: '',
      text: "Le SIE Haïti est un système d'information détenu par le Ministère de l'Environnement d'Haïti. Il soutient la collecte, la gestion et la diffusion des données environnementales pour améliorer la prise de décision et promouvoir le développement durable.",
      img: 'siehaiti.PNG',
      imgstyle: {},
      link: 'https://www.sie-haiti.org/#/'
    },
    {
      title: 'Geoportal DGPC (MangoMap)',
      subtitle: '',
      text: "Le géoportail de la Protection Civile en Haïti est une plateforme en ligne dédiée à la gestion et au partage des données géospatiales liées aux risques de catastrophe et à la gestion des urgences.",
      img: 'GeoportalDGPC.PNG',
      imgstyle: {},
      link: 'https://mangomap.com/direction-protection-civile-haiti/maps/118678/webmap-protection-civile- haiti?preview=true#zoom=8&amp;lat=19.051734&amp;lng=- 71.779175&amp;layergroups=Direction%20Protection%20Civile%20Haiti%3Ab408c2e2-ac3b-11eb-a110- 06765ea3034e,Direction%20Protection%20Civile%20Haiti%3A7ddfe1d4-ac48-11eb-b4a7- 06765ea3034e,Direction%20Protection%20Civile%20Haiti%3Abb629860-ac3b-11eb-b04e- 06765ea3034e,Direction%20Protection%20Civile%20Haiti%3A43125046-ac2f-11eb-b4a7- 06765ea3034e&amp;bck=grayscale&amp;permalink=true'
    },
    {
      title: 'RO Haiti',
      subtitle: '',
      text: "RO Haïti, ou **Recovery Observatory**, a été développé pour exploiter les données satellites dans le cadre de la récupération après les catastrophes, notamment après l'ouragan Matthew en 2016. La plateforme améliore l'intégration des informations géospatiales dans les efforts de reconstruction, soutenant une meilleure surveillance et gestion des impacts des catastrophes.",
      img: 'rohaiti.PNG',
      imgstyle: {},
      link: 'https://www.recovery-observatory.org/drupal/en'
    },
    {
      title: 'HDX',
      subtitle: '',
      text: "HDX (Humanitarian Data Exchange) est une plateforme ouverte gérée par le Bureau de la Coordination des Affaires Humanitaires (OCHA) des Nations Unies. Elle facilite le partage et l'accès aux données humanitaires, permettant ainsi une meilleure prise de décision et coordination lors des crises.",
      img: 'hdx.png',
      imgstyle: {},
      link: 'https://data.humdata.org/search?q=haiti'
    },
  ];

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <img src="cnigs-logo.png" className="card-img-top" alt="..." style={{ height: '70px', width: '70px' }} />
          <a className="navbar-brand" href="#">HaitiData.org</a>
          <span />
        </div>
      </nav>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">SDI HAITI</a></li>
          <li class="breadcrumb-item active" aria-current="page">Autres sources de données SIG</li>
        </ol>
      </nav>

      <div className="container"> {/* text-center */}

        <div className="row">
          <span>Dans cette section, l'utilisateur pourra en savoir plus à la vitrine des plateformes partenaires spécialisées dans les données géospatiales en Haïti. Ces plateformes offrent des outils variés pour gérer, partager et visualiser des informations spatiales, facilitant ainsi la collaboration et l'accès à des données essentielles pour le développement du pays</span>
        </div>

        <div className="row">
          {
            other_gis_data_sources
              .filter(
                item => item?.title?.toUpperCase()?.includes(filter?.toUpperCase())
                  || item?.subtitle?.toUpperCase()?.includes(filter?.toUpperCase())
                  || item?.text?.toUpperCase()?.includes(filter?.toUpperCase())
              )
              .map((item, index) =>
                <div key={index} className="col-lg-3 col-md-6 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">{item.title}</h5>
                      <h6 className="card-subtitle mb-2 text-body-secondary">{item.subtitle}</h6>
                      <p className="card-text">{item.text}</p>
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <img src={item.img} className="card-img-top" alt="..." style={item.imgstyle} />
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-center">
                      <a href={item.link} className="btn btn-primary" target="_blank">en savoir plus</a>
                    </div>
                  </div>
                </div>)
          }
        </div>
      </div>

      <Footer/>
    </>
  );
}

export default Others;
